import React, { useEffect, useContext, useState } from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { BrinkContext } from "../components/context/BrinkContext"
import { useTranslation, Trans } from "react-i18next"
import { containerMaxWidth } from "../constants"
import Layout from "../components/Layout"
import Breadcrumbs from "../components/ui/Breadcrumbs"
import ProductGrid from "../components/widgets/ProductGrid"

const Container = styled.div`
  max-width: ${containerMaxWidth};
  margin: 0 auto 6rem;
`

const Title = styled.h1`
  text-align: center;
  padding: 0 5rem;
  line-height: 3rem;
`

const TotalProducts = styled.p`
  text-align: center;
`

const NoResults = styled.div`
  text-align: center;

  p {
    padding: 0 3rem;
  }
`

const Query = styled.span`
  text-transform: initial;
  display: block;
  margin-top: 2rem;
  font-size: 2rem;
`

const SearchResultPage = ({ data: { allSanityProduct }, pageContext }) => {
  const { t } = useTranslation("translations")
  const { searchQuery, searchProducts, isLoading, searchId } =
    useContext(BrinkContext)

  const query = searchQuery || ""
  const [products, setProducts] = useState([])

  useEffect(() => {
    const loadSearch = async () => {
      const foundProducts = searchQuery ? await searchProducts(searchQuery) : []
      const products = foundProducts
        .map((product) => {
          if (product.type === "product") {
            return allSanityProduct.nodes.find(
              (sanityProduct) => sanityProduct._id === product.id
            )
          } else {
            return allSanityProduct.nodes
              .map((sanityProduct) => {
                if (
                  sanityProduct.variants.find(
                    (sanityVariant) => sanityVariant._id === product.id
                  )
                ) {
                  return sanityProduct
                } else {
                  return null
                }
              })
              .filter((x) => x)
          }
        })
        .flat()
        .reduce(
          (unique, item) =>
            unique.find((p) => p._id === item._id) ? unique : [...unique, item],
          []
        )
      setProducts(products)
    }
    loadSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchId])

  return (
    <Layout
      meta={{ title: t("Search results") }}
      invertedHeader
      pageContext={pageContext}
    >
      <Breadcrumbs>{t("Search")}</Breadcrumbs>
      {!isLoading && (
        <Container>
          {products.length > 0 ? (
            <>
              <Title>
                {t("Showing results for")} <Query>'{query}'</Query>
              </Title>
              <TotalProducts>
                {products.length}{" "}
                {products.length === 1 ? t("item") : t("items")}
              </TotalProducts>
              <ProductGrid columns="4" products={products} />
            </>
          ) : (
            <NoResults>
              <Title>{t("No matching items")}</Title>
              <p>
                <Trans i18nKey="searchQuery">
                  Your search '<strong>{{ query }}</strong>' did not match any
                  results.
                </Trans>
                <br />
                {t(
                  "Please check the spelling or try again with a less specific term."
                )}
              </p>
            </NoResults>
          )}
        </Container>
      )}
    </Layout>
  )
}

export default SearchResultPage

export const query = graphql`
  query {
    allSanityProduct {
      nodes {
        _id
        displayName {
          en
          de
        }
        slug {
          current
        }
        variants {
          ...ProductVariants
        }
      }
    }
  }
`
